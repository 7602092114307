import Context from './AppContext'
import _ from 'lodash'
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import {
	ActionProps,
	CompEventSymbol,
	CompProps,
	PropsMap,
	StateRefsValues,
	StateRefsValuesMap,
	Store,
} from '@wix/thunderbolt-symbols'
import { CompController } from '@wix/thunderbolt-components-loader'
import { CompModifiedStores, ModifiedValuesStore } from '../types'

const emptyPropsFunc = () => ({})

// compController can be
// 1. function for mapActionsToProps
// 2. object with optional function fields mapStateToProps, mapActionsToProps
const extractControllerMappers = (compController: CompController) => {
	// TODO: remove backward compatibility once https://jira.wixpress.com/browse/TB-4863 is resolved
	const isOldController = _.isFunction(compController)
	const mapActionsToProps = isOldController ? compController : compController?.mapActionsToProps || emptyPropsFunc
	const mapStateToProps = isOldController ? emptyPropsFunc : compController?.mapStateToProps || emptyPropsFunc

	return {
		mapActionsToProps,
		mapStateToProps,
	}
}

const isVisibilityHidden = (compId: string): boolean => {
	const elem = document.getElementById(compId)
	return elem ? window.getComputedStyle(elem).visibility === 'hidden' : false
}

const getFunctionWithEventProps = (
	fnName: string,
	fn: Function & { [CompEventSymbol]?: boolean },
	displayedId: string
) => (...args: Array<never>) => {
	// overcome react bug where onMouseLeave is emitted if element becomes hidden while hovered
	// https://github.com/facebook/react/issues/22883
	if (fnName === 'onMouseLeave' && isVisibilityHidden(displayedId)) {
		return
	}

	return fn[CompEventSymbol] ? fn({ args, compId: displayedId }) : fn(...args)
}

const addEventProps = (
	currentStoreRef: ModifiedValuesStore<CompProps | StateRefsValues>,
	displayedId: string,
	compProps: CompProps
) => {
	if (!compProps) {
		currentStoreRef.values = compProps
		return compProps
	}

	let useValuesFromPrevRender = true
	const compPropsKeys = Object.keys(compProps)
	const cacheKeys = Object.keys(currentStoreRef.modifiedValues)
	const modifiedProps = compPropsKeys.reduce((acc: CompProps | StateRefsValues, propName) => {
		const propValue = compProps[propName]
		if (propValue !== currentStoreRef.values[propName]) {
			useValuesFromPrevRender = false
			if (typeof propValue === 'function') {
				return { ...acc, [propName]: getFunctionWithEventProps(propName, propValue, displayedId) }
			}
			return { ...acc, [propName]: propValue }
		}
		return { ...acc, [propName]: currentStoreRef.modifiedValues[propName] }
	}, {})
	currentStoreRef.values = compProps
	return useValuesFromPrevRender && _.isEqual(compPropsKeys, cacheKeys)
		? currentStoreRef.modifiedValues
		: modifiedProps
}

const useControllerMappers = (displayedId: string, compType: string, compProps: CompProps, stateValues: CompProps) => {
	const { createCompControllerArgs, compControllers } = useContext(Context)
	const compController = compControllers[compType]
	const { mapActionsToProps, mapStateToProps } = extractControllerMappers(compController)
	const stateProps = useMemo(() => mapStateToProps(stateValues, compProps), [stateValues, compProps, mapStateToProps])

	const controllerActions: ActionProps = useMemo(
		() => mapActionsToProps(createCompControllerArgs(displayedId, stateValues)),
		[displayedId, createCompControllerArgs, stateValues, mapActionsToProps]
	)

	const compOwnActions: ActionProps = useMemo(() => {
		return Object.keys(controllerActions).reduce(
			(acc, actionName) => (compProps[actionName] ? { ...acc, [actionName]: compProps[actionName] } : acc),
			{}
		)
	}, [controllerActions, compProps])

	const mergedControllerActions = useMemo(() => {
		return Object.entries(compOwnActions).reduce((acc, [actionName, action]) => {
			return {
				...acc,
				[actionName]: (...args: Array<any>) => {
					controllerActions[actionName](...args)
					action(...args)
				},
			}
		}, {})
	}, [compOwnActions, controllerActions])

	return { ...compProps, ...controllerActions, ...mergedControllerActions, ...stateProps }
}

const getRepeatedValues = (
	currentStoreRef: ModifiedValuesStore<CompProps | StateRefsValues>,
	store: Store<PropsMap> | Store<StateRefsValuesMap>,
	isRepeatedComp: boolean,
	compId: string,
	displayedId: string
) => {
	const repeatedValues = isRepeatedComp
		? { ...store.get(compId), ...store.get(displayedId) }
		: store.get(compId) ?? {}
	return addEventProps(currentStoreRef, displayedId, repeatedValues)
}

const updateModifiedValuesStore = (
	compStoresRef: CompModifiedStores,
	compStateValues: CompProps,
	compProps: CompProps
) => {
	compStoresRef.stateRefs.modifiedValues = compStateValues
	compStoresRef.props.modifiedValues = compProps
}

export const useProps = (displayedId: string, compId: string, compType: string) => {
	// create a ref that holds current values store values and values after modifications for event functions
	// in order to reduce redundant renders by comparing the ref values between render cycles
	const compStoresRef = useRef<CompModifiedStores>({
		props: { modifiedValues: {}, values: {} },
		stateRefs: { modifiedValues: {}, values: {} },
	}).current
	const { compControllers, props: propsStore, stateRefs: stateRefsStore } = useContext(Context)
	const isRepeatedComp = displayedId !== compId
	const compProps = getRepeatedValues(compStoresRef.props, propsStore, isRepeatedComp, compId, displayedId)
	const compStateValues = getRepeatedValues(
		compStoresRef.stateRefs,
		stateRefsStore,
		isRepeatedComp,
		compId,
		displayedId
	)
	const compPropsAndStateValues = compControllers[compType] // eslint-disable-next-line react-hooks/rules-of-hooks
		? useControllerMappers(displayedId, compType, compProps, compStateValues)
		: compProps

	// since comp store values are mutable (due to useRef), all dependent hooks must be calculated before we update store
	// with modified values to avoid incorrect memoization when props/stateRefs are updated
	updateModifiedValuesStore(compStoresRef, compStateValues, compProps)
	return compPropsAndStateValues
}

export const useStoresObserver = (id: string, displayedId: string): void => {
	const { structure: structureStore, props: propsStore, compsLifeCycle, stateRefs: stateRefsStore } = useContext(
		Context
	)

	const [, setTick] = useState(0)
	const forceUpdate = useCallback(() => setTick((tick) => tick + 1), [])

	const subscribeToStores = () => {
		compsLifeCycle.notifyCompDidMount(id, displayedId) // we call it when the id\displayed id changes although it's not mount
		const stores = [propsStore, structureStore, stateRefsStore]
		const unSubscribers: Array<() => void> = []
		stores.forEach((store) => {
			const unsubscribe = store.subscribeById(displayedId, forceUpdate)
			unSubscribers.push(unsubscribe)
			if (displayedId !== id) {
				forceUpdate() // sync repeated component props with stores props in case stores props were updated during first render
				unSubscribers.push(store.subscribeById(id, forceUpdate))
			}
		})

		return () => {
			unSubscribers.forEach((cb) => cb())
		}
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(subscribeToStores, [id, displayedId])
}
